import { useEffect, useState } from 'react';

import {
  RightOutlined,
  LeftOutlined,
  AppstoreOutlined,
  // DashboardOutlined,
  SettingOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  SyncOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, MenuProps, Modal, Progress, SiderProps, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { catchError, forkJoin, map } from 'rxjs';

import styles from './LeftSider.module.less';
import './leftPanel.css';
import { LeftPanelWidth, MenuItem } from '@/common/define';
import { getEnvVars } from '@/environment';
import { IssueService, ProjectSyncAudit } from '@/services/IssueService';
import { ProjectService } from '@/services/ProjectService';
import { appActions, getActiveMenu, getAuthenticated } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, getTrackerId, issueActions } from '@/store/issue';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

const { Sider } = Layout;
const { apiUrl } = getEnvVars();
var count = 0;
var eventSource: EventSource | null = null;

const { confirm } = Modal;
interface ReportError {
  pipingTasks: string[];
  remainPipingTasks: string[];
  testPackageTasks: string[];
}

export const LeftSider = (props: SiderProps) => {
  const { ...rest } = props;
  const { t } = useTranslation(['layout']);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tracker = useAppSelector(getTracker());
  const [openKeys, setOpenKeys] = useState(['/projects/employees']);
  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const trackerId = useAppSelector(getTrackerId());

  const isSyncingPAMS = useAppSelector(getLoading('syncPams'));
  const isAsyncPiping = useAppSelector(getLoading('asyncPiping'));
  const isAsyncRemain = useAppSelector(getLoading('asyncRemain'));
  const isAsyncTestPackage = useAppSelector(getLoading('asyncTestPackage'));

  const syncPipingTaskStatus = useAppSelector(state => state.issue.syncPipingTaskStatus);
  const syncRemainPipingTaskStatus = useAppSelector(state => state.issue.syncRemainPipingTaskStatus);
  const syncTestPackageTaskStatus = useAppSelector(state => state.issue.syncTestPackageTaskStatus);

  const [showSyncDialog, setShowSyncDialog] = useState(false);
  const [errorFiles, setErrorFiles] = useState<any>();

  const [isSynchronizing, setSynchronizing] = useState(false);
  const [processPercent, setProcessPercent] = useState(0);
  const auth = useAppSelector(getAuthenticated());

  const [errorReport, setErrorReport] = useState<ReportError>({
    pipingTasks: [],
    remainPipingTasks: [],
    testPackageTasks: [],
  });

  const saveReportFiles = async (projectId: number, comanyId: number, reportError: ReportError) => {
    const url = `https://uat.pcvm.hicas.vn/api/Pcvm/saveReportFiles/${projectId}?projectIdLong=${projectId}&comanyId=${comanyId}`;
    console.log('error url', url);
    try {
      const requestBody = {
        pipingTasks: reportError.pipingTasks,
        remainPipingTasks: reportError.remainPipingTasks,
        testPackageTasks: reportError.testPackageTasks,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
        body: JSON.stringify(requestBody),
      });

      console.log('requestBody', requestBody);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!isAsyncPiping && !isAsyncRemain && !isAsyncTestPackage) {
      if (showSyncDialog) {
        setTimeout(() => {
          console.log('showSyncDialog');
          setShowSyncDialog(false);
          if (selectedProject) {
            if (
              syncPipingTaskStatus?.syncStatus === 2 ||
              syncRemainPipingTaskStatus?.syncStatus === 2 ||
              syncTestPackageTaskStatus?.syncStatus === 2
            ) {
              dispatch(
                issueActions.updateProjectSyncAuditRequest({
                  projectId: selectedProject.id,
                  auditData: {
                    projectId: selectedProject.id,
                    syncStatus: 2,
                    syncType: 1,
                    createdAt: new Date().toISOString(),
                    startTime: new Date().toISOString(),
                    endTime: new Date().toISOString(),
                    errorMessage: '',
                    note: 'Starting sync process',
                  },
                }),
              );
            } else if (
              syncPipingTaskStatus?.syncStatus === 3 ||
              syncRemainPipingTaskStatus?.syncStatus === 3 ||
              syncTestPackageTaskStatus?.syncStatus === 3
            ) {
              dispatch(
                issueActions.updateProjectSyncAuditRequest({
                  projectId: selectedProject.id,
                  auditData: {
                    projectId: selectedProject.id,
                    syncStatus: 3,
                    syncType: 1,
                    createdAt: new Date().toISOString(),
                    startTime: new Date().toISOString(),
                    endTime: new Date().toISOString(),
                    errorMessage: '',
                    note: 'End sync',
                  },
                }),
              );
            }
            if (
              errorFiles?.pipingTask?.length > 0 ||
              errorFiles?.testPackageTask?.length > 0 ||
              errorFiles?.remainPipingTask?.length > 0
            ) {
              saveJsonToFile(errorFiles);
            }
          }
        }, 1000);
      }
    }
  }, [isAsyncPiping, isAsyncRemain, isAsyncTestPackage]);

  const GetSynchronizing = () => {
    const url = `${apiUrl}/api/Pcvm/sync-progress?projectId=${selectedProject?.id}`;
    const source = new EventSource(url);
    count = 0;
    source.onopen = () => {
      console.log('Connection to server opened.');
    };
    source.onmessage = event => {
      let response = JSON.parse(event.data);
      let percent = Number(response.Progress ?? '0');

      setProcessPercent(percent);
      if (percent >= 0 && percent < 100) {
        setSynchronizing(true);
      } else if (percent === 100) {
        eventSource?.close();
        setSynchronizing(false);
      } else {
        eventSource?.close();
        setSynchronizing(false);
      }
    };
    source.onerror = event => {
      count++;
    };

    if (eventSource != null) {
      eventSource.close();
      eventSource = null;
    }
    eventSource = source;
  };

  const CancelSync = () => {
    if (selectedProject) {
      const url = `${apiUrl}/api/Pcvm/stop-progress/${selectedProject.id}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            eventSource?.close();
            eventSource = null;

            setSynchronizing(false);
            setProcessPercent(0);
          } else {
            console.error('Failed to stop sync:', response.statusText);
          }
        })
        .catch(error => {
          console.error('Error stopping sync:', error);
        });
    }
  };

  useEffect(() => {
    console.log('Synchronizing đang là ', isSynchronizing);
  }, [isSynchronizing]);

  const handleOk = () => {
    setSynchronizing(false);
    navigate('/');
  };

  const handleCancel = () => {
    CancelSync();
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
    setProcessPercent(0);
    setSynchronizing(false);
  };

  useEffect(() => {
    GetSynchronizing();
  });

  const mainMenu: MenuItem[] = [
    {
      label: t('Projects'),
      icon: <AppstoreOutlined />,
      key: '/',
    },
  ];

  const projectMenu: MenuItem[] = [
    {
      label: t('Based data'),
      icon: <DatabaseOutlined />,
      key: '/projects/based-data',
      children: [
        {
          label: t('Project volume plan total'),
          key: '/projects/based-data/volume-total-plan',
        },
        {
          label: t('Areas'),
          key: '/projects/based-data/area-list',
        },
        {
          label: t('Work packages'),
          key: '/projects/based-data/work-packages',
        },
        {
          label: t('Documents'),
          key: '/projects/based-data/documents',
        },
        {
          label: t('Linked documents'),
          key: '/projects/based-data/linked-documents',
        },
        {
          label: t('Structure base set'),
          key: '/projects/based-data/structure-base-set',
        },
      ],
    },
    {
      label: t('Tasks'),
      icon: <CalendarOutlined />,
      key: '/projects/tasks',
      children: [
        {
          label: t('Piping'),
          key: '/projects/tasks/piping',
        },
        {
          label: t('Equipment'),
          key: '/projects/tasks/equipment',
        },
        {
          label: t('E&I'),
          key: '/projects/tasks/e-and-i',
        },
        {
          label: t('Structure'),
          key: '/projects/tasks/structure',
        },
        {
          label: t('Civil'),
          key: '/projects/tasks/civil',
        },
        {
          label: t('Remain Piping Workload'),
          key: '/projects/tasks/remain-piping-workload',
        },
        {
          label: t('Test package'),
          key: '/projects/tasks/test-package-s',
        },
      ],
    },
  ];
  const formatFileName = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear()).slice(-2);
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `errors ${day}-${month}-${year} ${hours}:${minutes}.txt`;
  };
  const saveJsonToFile = (jsonObject: any) => {
    const fileName = formatFileName();
    const jsonString = JSON.stringify(jsonObject, null, 2); // Chuyển đổi JSON thành chuỗi có định dạng đẹp

    // Tạo một Blob từ chuỗi JSON
    const blob = new Blob([jsonString], { type: 'text/plain' });

    // Tạo một link tạm thời và kích hoạt tải file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Sau khi tải xong, giải phóng bộ nhớ được cấp phát bởi URL.createObjectURL
    URL.revokeObjectURL(link.href);
  };
  const deleteIssuesByProjectRequest = async (projectId: number, search: any) => {
    try {
      // Make async request here
      let data = await IssueService.Delete.deleteIssuesByProject(projectId, { search }).toPromise();
      console.log(data);
    } catch (error) {}
  };
  const handleResetTasks = async () => {
    if (selectedProject && tracker?.results) {
      const trackers = tracker.results.filter(item =>
        ['PipingTask', 'PipingTestPackage', 'PipingAreaWorkload'].includes(item.code),
      );

      for (const trackerItem of trackers) {
        // Await the dispatch call to ensure it completes before moving to the next
        await deleteIssuesByProjectRequest(selectedProject.id, {
          areaId: -1,
          workPackageId: -1,
          trackerId: trackerItem.id,
        });
      }
    }
  };

  const syncPAMS = async () => {
    if (selectedProject) {
      let projectId = selectedProject.id;
      let companyId = selectedProject.companyId;
      dispatch(startLoading({ key: 'GetIssuesByTracker' }));

      forkJoin({
        pipingTaskResponse: ProjectService.Put.validatePipingTask(projectId),
        testPackageTaskResponse: ProjectService.Put.validateTeskPackageTaskPAMs(projectId),
        remainPipingTaskResponse: ProjectService.Put.validateRemainPipingTaskPAMs(projectId),
      })
        .pipe(
          map(({ pipingTaskResponse, testPackageTaskResponse, remainPipingTaskResponse }) => {
            return {
              projectId,
              trackerId,
              pipingTaskResponse,
              remainPipingTaskResponse,
              testPackageTaskResponse,
            };
          }),
        )
        .subscribe({
          next: syncPAMSRequestData => {
            const errors = {
              pipingTask: syncPAMSRequestData.pipingTaskResponse?.errors,
              testPackageTask: syncPAMSRequestData.testPackageTaskResponse?.errors,
              remainPipingTask: syncPAMSRequestData.remainPipingTaskResponse?.errors,
            };

            setErrorReport({
              pipingTasks: errors.pipingTask,
              remainPipingTasks: errors.remainPipingTask,
              testPackageTasks: errors.testPackageTask,
            });

            // Tạo báo cáo lỗi
            const reportError: ReportError = {
              pipingTasks: errors.pipingTask,
              remainPipingTasks: errors.remainPipingTask,
              testPackageTasks: errors.testPackageTask,
            };
            console.log('lỗi là', reportError);
            saveReportFiles(projectId, companyId, reportError); // gọi hàm saveReportFile

            if (
              errors.pipingTask?.length > 0 ||
              errors.testPackageTask?.length > 0 ||
              errors.remainPipingTask?.length > 0
            ) {
              setErrorFiles(errors);
              confirm({
                title: 'Notification',
                content: 'Null or invalid values exist. Do you want to sync this data?',
                onOk: () => {
                  const auditData: ProjectSyncAudit = {
                    projectId,
                    syncStatus: 0,
                    syncType: 1,
                    createdAt: new Date().toISOString(),
                    startTime: new Date().toISOString(),
                    endTime: new Date().toISOString(),
                    errorMessage: '',
                    note: 'Starting sync process',
                  };
                  console.log('Dữ liệu khởi tạo khi gọi:', auditData);
                  dispatch(issueActions.createProjectSyncAuditRequest({ input: auditData }));

                  setShowSyncDialog(true);
                  dispatch(
                    issueActions.syncPAMSRequest({
                      projectId,
                      trackerId,
                      PipingTaskDataSync: syncPAMSRequestData.pipingTaskResponse?.pamsTaskAvaialbles,
                      RemainPipingDataSync: syncPAMSRequestData.remainPipingTaskResponse?.pamsTaskAvaialbles,
                      TestPackageDataSync: syncPAMSRequestData.testPackageTaskResponse?.pamsTaskAvaialbles,
                      PipingTaskError: syncPAMSRequestData.pipingTaskResponse?.errors,
                      RemainPipingError: syncPAMSRequestData.remainPipingTaskResponse?.errors,
                      TestPackageError: syncPAMSRequestData.testPackageTaskResponse?.errors,
                    }),
                  );
                },
                onCancel: () => {
                  dispatch(stopLoading({ key: 'GetIssuesByTracker' }));
                },
              });
            } else {
              setShowSyncDialog(true);
              dispatch(
                issueActions.syncPAMSRequest({
                  projectId,
                  trackerId,
                  PipingTaskDataSync: syncPAMSRequestData.pipingTaskResponse?.pamsTaskAvaialbles,
                  RemainPipingDataSync: syncPAMSRequestData.remainPipingTaskResponse?.pamsTaskAvaialbles,
                  TestPackageDataSync: syncPAMSRequestData.testPackageTaskResponse?.pamsTaskAvaialbles,
                  PipingTaskError: syncPAMSRequestData.pipingTaskResponse?.errors,
                  RemainPipingError: syncPAMSRequestData.remainPipingTaskResponse?.errors,
                  TestPackageError: syncPAMSRequestData.testPackageTaskResponse?.errors,
                }),
              );
            }
          },
          error: error => {
            console.error('Error validating tasks:', error);
            dispatch(stopLoading({ key: 'GetIssuesByTracker' }));
            Utils.errorHandling(error);
          },
        });
    }
  };

  const bottomMenu: MenuItem[] = [
    {
      label: t('Project settings'),
      icon: <SettingOutlined />,
      key: '/projects/settings',
    },
  ];

  const [avatar, setAvatar] = useState<string>();
  useEffect(() => {
    if (selectedProject) {
      setAvatar(
        selectedProject.avatar &&
          selectedProject.avatar !== 'string' &&
          selectedProject.avatar !== '' &&
          selectedProject.avatar !== null
          ? selectedProject.avatar.includes('http')
            ? selectedProject.avatar
            : apiUrl + '/Projects' + selectedProject.avatar
          : undefined,
      );
    }
  }, [selectedProject, setAvatar]);

  useEffect(() => {
    const { pathname } = location;
    const menus: any = projectMenu.concat(bottomMenu).concat(mainMenu);
    for (const item of menus) {
      if (item?.key === pathname) {
        const { label, key } = item;
        dispatch(appActions.setActiveMenu({ label, key }));
      }
      if (item?.children) {
        for (const child of item.children) {
          if (child.key === pathname) {
            const { label, key } = child;
            dispatch(appActions.setActiveMenu({ label, key }));
            if (!collapsed) {
              setOpenKeys([item.key]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [location, collapsed]);

  const onClickMenu = (menu: any) => {
    GetSynchronizing();

    const { key } = menu;
    navigate(key);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleLeftPanelVisibility = () => {
    setCollapsed(prev => !prev);
  };

  // const handleLogout = () => {
  //   auth.signout(() => {
  //     changeStoreConfig({});
  //     navigate('/login');
  //   });
  // };
  const handlebutton = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Sider
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={LeftPanelWidth}
      onCollapse={setCollapsed}
      className={styles.main}
      {...rest}
    >
      <div className={styles.menusWrapper}>
        <div
          style={{
            display: 'flex',
            margin: collapsed ? 0 : 5,
            height: !selectedProject ? 0 : collapsed ? 0 : 65,
          }}
        >
          {selectedProject && (
            <>
              <Avatar shape="square" size={64} src={avatar} style={{ display: collapsed ? 'none' : 'block' }} />
              <div style={{ marginLeft: 5, paddingTop: 5, display: collapsed ? 'none' : 'block' }}>
                <b>{selectedProject?.name}</b>
              </div>
            </>
          )}
          <Button
            shape="circle"
            size="small"
            onClick={handleLeftPanelVisibility}
            className={styles.toggleButton}
            icon={collapsed ? <RightOutlined style={{ fontSize: 11 }} /> : <LeftOutlined style={{ fontSize: 11 }} />}
            style={{
              transform: collapsed ? 'translateX(-110%)' : 'translateX(-10%)',
              top: selectedProject ? 47 : '10px',
            }}
          />
        </div>
        {collapsed && selectedProject && (
          <div style={{ marginTop: 40, marginLeft: 7 }}>
            <Tooltip title={selectedProject?.name}>
              <Avatar shape="square" size={64} src={avatar} />
            </Tooltip>
          </div>
        )}
        {!selectedProject && (
          <Menu
            mode="inline"
            onClick={({ key }) => {
              navigate(key);
            }}
            selectedKeys={[activeMenu?.key]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={mainMenu}
            inlineIndent={10}
            className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
          />
        )}
        {selectedProject && (
          <>
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={projectMenu}
              inlineIndent={5}
              className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
            />
            {selectedProject && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: collapsed ? 'center' : 'flex-start',
                  padding: 10,
                  backgroundColor: 'white',
                  boxShadow: '-1px -1px 11px -6px rgba(0,0,0,0.75)',
                  borderBottom: '1px solid #0000001c',
                }}
              >
                {collapsed ? (
                  <Tooltip title={`Sync PAMS`} placement="right">
                    <Button type="primary" icon={<SyncOutlined />} loading={isSyncingPAMS} onClick={() => syncPAMS()} />
                  </Tooltip>
                ) : (
                  <>
                    {' '}
                    <SyncOutlined style={{ marginRight: 10, marginLeft: 5, fontSize: 16 }} />
                    <Button type="primary" loading={isSyncingPAMS} onClick={() => syncPAMS()}>
                      {`Sync PAMS`}
                    </Button>
                  </>
                )}
              </div>
            )}
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              items={bottomMenu}
              inlineIndent={5}
              className={styles.bottom_menu}
            />
          </>
        )}
        {/* {!!auth.user && (
          <div
            className={styles.avatarContainer}
            style={{
              justifyContent: collapsed ? 'center' : 'flex-start',
              paddingInline: collapsed ? '4px 5px' : '5px 0px',
            }}
          >
            <div className={styles.avatarContainer_wrapper}>
              {!collapsed && (
                <>
                  <Avatar
                    size={26}
                    style={{ backgroundColor: Utils.stringToColour(auth?.user?.Id) }}
                  >
                    {auth.user.Firstname.charAt(0)}
                  </Avatar>
                  <Typography.Text
                    className={`${styles.avatarContainer_userName} ${collapsed ? styles.textCollapsed : ''}`}
                    strong
                  >
                    {`${auth.user.Lastname} ${auth.user.Firstname}`.trim()}
                  </Typography.Text>
                  <Button type="text" shape='circle' className={styles.logoutButton} onClick={handleLogout}>
                    <img src={LogoutIcon} alt="logout" />
                  </Button>
                </>
              )}
              {collapsed && (
                <Button type="text" shape='circle' className={styles.logoutButton} onClick={handleLogout}>
                  <img src={LogoutIcon} alt="logout" />
                </Button>
              )}
            </div>
          </div>
        )} */}
      </div>
      <Modal
        open={showSyncDialog}
        footer={null}
        centered
        title={'Synchronized PAMS Proccess'}
        closeIcon={null}
        closable={false}
      >
        <div>
          <p>
            {isAsyncPiping ? (
              <>
                <LoadingOutlined style={{ marginRight: 5 }} />
                {syncPipingTaskStatus?.message && syncPipingTaskStatus?.message.length > 0
                  ? syncPipingTaskStatus?.message
                  : 'Syncing Piping Task...'}
              </>
            ) : syncPipingTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green', marginRight: 5 }} /> {syncPipingTaskStatus?.message}
              </>
            ) : syncPipingTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red', marginRight: 5 }} /> {syncPipingTaskStatus?.message}
              </>
            ) : (
              <></>
            )}
          </p>
          <p>
            {isAsyncRemain ? (
              <>
                <LoadingOutlined style={{ marginRight: 5 }} />
                {syncRemainPipingTaskStatus?.message && syncRemainPipingTaskStatus?.message.length > 0
                  ? syncRemainPipingTaskStatus?.message
                  : 'Syncing Piping Remain...'}
              </>
            ) : syncRemainPipingTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green', marginRight: 5 }} />
                {syncRemainPipingTaskStatus?.message}
              </>
            ) : syncRemainPipingTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red', marginRight: 5 }} /> {syncRemainPipingTaskStatus?.message}
              </>
            ) : (
              <></>
            )}
          </p>
          <p>
            {isAsyncTestPackage ? (
              <>
                <LoadingOutlined style={{ marginRight: 5 }} />
                {syncTestPackageTaskStatus?.message && syncTestPackageTaskStatus?.message.length > 0
                  ? syncTestPackageTaskStatus?.message
                  : 'Syncing Piping Test Package...'}
              </>
            ) : syncTestPackageTaskStatus?.status === 1 ? (
              <>
                <CheckCircleOutlined style={{ color: 'green', marginRight: 5 }} /> {syncTestPackageTaskStatus?.message}
              </>
            ) : syncTestPackageTaskStatus?.status === 2 ? (
              <>
                <CloseCircleOutlined style={{ color: 'red', marginRight: 5 }} /> {syncTestPackageTaskStatus?.message}
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
      </Modal>
      <Modal
        title="Synchronization in Progress"
        visible={isSynchronizing}
        closable={false}
        maskClosable={false}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="OK"
      >
        <p>Documents are being synchronized. Please wait...</p>
        <Progress width={100} percent={processPercent} />
      </Modal>
    </Sider>
  );
};
